import { graphql, Link } from 'gatsby' 
import React, { useState } from 'react'

import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'

import Helmet from "react-helmet"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Collapse from 'react-bootstrap/Collapse';

interface Props {
  location: Location
}

const Contact: React.FC<Props> = ({ location }: Props) => {
  const [validated, setValidated] = React.useState(false)

  const handleCaptcha = e => {
    window.grecaptcha.ready(function() {
      window.grecaptcha
      .execute("6LfTzKwbAAAAADIlZuHGHnafR7avLdOYju45qFXU", { action: "submit" }) 
      .then(function(token) {
        var recaptchaResponse = document.getElementById("g-recaptcha")
        recaptchaResponse.value = token
      })
    })
  }


  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }
  
  const [refopen, setRefOpen] = useState(false);
  const [otheropen, setOtherOpen] = useState(false);

  return (
    <Layout location={location}>
      <Meta site={Meta} title="Contact Us" />
      <Helmet
        bodyAttributes={{
          class: "page-contact-us",
        }}
      />
      <div className="container px-6">
        <section>
          <div className="container">
            <h1>Contact Us</h1>
            <Row>
              <Col md={8}>
                <p>Complete the form below and we'll be in touch. <br /><small>(<span className="required"></span> fields are required)</small></p>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="w-md-75 mx-auto"
                  method={"POST"}
                  encType={"multipart/form-data"}
                  action={"/send.php"} 
                >
                  <Form.Group controlId="formName">
                    <Form.Label className="required">Name</Form.Label>
                    <Form.Control required type="text" name="formName" />
                  </Form.Group>


                  <Form.Group controlId="formCompany">
                    <Form.Label className="required">Company</Form.Label>
                    <Form.Control required type="text" name="formCompany" />
                  </Form.Group>

                  <Form.Group controlId="formPhone">
                    <Form.Label className="required">Phone</Form.Label>
                    <Form.Control required type="tel" name="formPhone" />
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <Form.Control required type="email" name="formEmail" />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="">How did you hear about us?</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Email"
                      id="email"
                      name="form-referredby"
                      value="Email"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="LinkedIn"
                      id="linkedin"
                      name="form-referredby"
                      value="LinkedIn"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Facebook"
                      id="facebook"
                      name="form-referredby"
                      value="Facebook"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Google Business"
                      id="google"
                      name="form-referredby"
                      value="Google Business"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Web search"
                      id="websearch"
                      name="form-referredby"
                      value="Web search"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Referral"
                      id="referral"
                      name="form-referredby"
                      value="Referral"
                      onClick={() => { setRefOpen(true); if (otheropen) {setOtherOpen(!otheropen)};}}
                      aria-controls="referral-name"
                      aria-expanded={refopen}
                    />
                    <Form.Check
                      type="radio"
                      label="Other"
                      id="other"
                      name="form-referredby"
                      value="Other"
                      onClick={() => { setOtherOpen(true); if (refopen) {setRefOpen(!refopen)};}}
                      aria-controls="other-details"
                      aria-expanded={otheropen}
                    />
                  </Form.Group>

                  <Collapse in={refopen}>
                    <Form.Group controlId="formReferrer" id="referral-name">
                      <Form.Label>Referral Name</Form.Label>
                      <div className="form-text text-muted">That's great to hear! Please let us know who referred you so that we can extend our thanks.</div>
                      <Form.Control type="text" name="formReferrer" />
                    </Form.Group>
                  </Collapse>

                  <Collapse in={otheropen}>
                    <Form.Group controlId="formReferrer" id="other-details">
                      <Form.Label>Other Details</Form.Label>
                      <div className="form-text text-muted">Great! Please tell us a bit more about how you heard about us using the field below.</div>
                      <Form.Control type="text" name="otherDetails" />
                    </Form.Group>
                  </Collapse>

                  <Form.Group controlId="formMoreInfo" onFocus={e => handleCaptcha(e)}>
                    <Form.Label className="required">What can we help you with?</Form.Label>
                    <Form.Control required name="formMoreInfo" as="textarea" rows={5} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Do you have a contact preference?</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Phone"
                      id="method-phone"
                      name="form-contactMethod-Phone"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Email"
                      id="method-email"
                      name="form-contactMethod-Email"
                    />
                  </Form.Group> 

                  <Form.Group>
                    <Form.Label>Upload a file</Form.Label>
                    <Form.Control
                      type="file"
                      id="attachment"
                      name="attachment"
                    />
                    <small id="attachHelp" className="form-text text-muted">Uploaded files must be less than 10 MB. Allowed file types: pdf, doc, docx, png, jpg, gif. </small>
                  </Form.Group>

                  <Form.Control
                    type="hidden"
                    id="g-recaptcha"
                    name="g_recaptcha"
                    required
                  ></Form.Control>

                  <Button variant="btn btn-success" type="submit" className="mt-3">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col md={4}>
                <address>
                  <strong>Center for Building Innovation</strong> <br />
                  6300 Enterprise Lane <br />
                  Madison, WI 53719 <br /><br />
                  <i className="fa fa-phone" aria-hidden="true"></i> <a href="tel:16083106739">608-310-6739</a>
                </address>                
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
